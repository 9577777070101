import {Header} from "../../../components/header/Header";
import React, {ChangeEvent, useEffect, useState} from "react";
import {Payee} from "../../../domain/payee/Payee";
import {useNavigate, useParams} from "react-router-dom";
import {Address} from "../../../domain/core/Address";
import PayeeService from "../../../service/payee/payeeService";
import '../payeeForm/payeeForm.scss'
import type SlInputElement from "@shoelace-style/shoelace/dist/components/input/input.component";
import SlInput from "@shoelace-style/shoelace/dist/react/input";
import authService from "../../../service/auth/auth.service";
import {PayeeBankInfo} from "../../../domain/payee/PayeeBankInfo";

type PayorProps = {
    payor:Payee
}

export const PayeeForm = (): JSX.Element => {
    useEffect(() => {
        getData();
    }, []);
    const isAdmin: boolean =  authService.isAdmin();
    const navigate = useNavigate();
    const { id } = useParams() as any
    const [payee, setPayee] = useState<Payee>({
        address: new Address(),
        dateOnbordingSent: '',
        distributerPlatformId: "",
        stripeAccountToken:"",
        emailAddress: "",
        id: 0,
        linkExpirationDate: '',
        name: "",
        nonElectronicPayee: false,
        parentPlatformId: "",
        status: "",
        apiUser:""
    });
    const[address,setAddress] = useState<Address>({
        addressLine1:'',
        addressLine2:'',
        city:'',
        state:'',
        postalCode:'',
        country:''
    })
    const[payeeBankinfo,setPayeeBankinfo] = useState<PayeeBankInfo>({
        accountHolderName: "",accountHolderType:"", accountNumber: "", routingNumber: ""
    })

    const[nameError,setNameError] = useState("");
    const[customerTokenError,setCustomerTokenError] = useState("");
    const[emailError,setEmailError] = useState("");
    const[platformIdError,setPlatformIdError] = useState("");

    const getData = () => {
        PayeeService.getPayeeById(id).then((payeeData)=>{
                moveData(payeeData)
                getPayeeBankInfo(payeeData.distributerPlatformId)
            }
        )

    }

    const getPayeeBankInfo = (platormId:string) =>{
        PayeeService.payeebankInfo(platormId).then((payeeBankData)=>{
                setPayeeBankinfo(payeeBankData)
            }
        )
    }

    const moveData = (response:Payee) => {
        setPayee(payee => ({...payee, id: response.id}));
        setPayee(payee => ({...payee, name: response.name}));
        setPayee(payee => ({...payee, distributerPlatformId: response.distributerPlatformId}));
        if(response.parentPlatformId!=null) {
            setPayee(payee => ({...payee, parentPlatformId: response.parentPlatformId}));
        }
        if(response.stripeAccountToken!=null) {
            setPayee(payee => ({...payee, stripeAccountToken: response.stripeAccountToken}));
        }
        setPayee(payee => ({...payee, emailAddress: response.emailAddress}));
        if(response.address.addressLine1!=null){
            setAddress(address => ({...address,addressLine1:response.address.addressLine1}));
        }
        if(response.address.addressLine2!=null){
            setAddress(address => ({...address,addressLine2:response.address.addressLine2}));
        }
        if(response.address.city!=null){
            setAddress(address => ({...address,city:response.address.city}));
        }
        if(response.address.state!=null){
            setAddress(address => ({...address,state:response.address.state}));
        }
        if(response.address.postalCode!=null){
            setAddress(address => ({...address,postalCode:response.address.postalCode}));
        }
        setPayee(payee => ({...payee, status: response.status}));
        if(response.dateOnbordingSent!=null){
            setPayee(payee => ({...payee, dateOnbordingSent: response.dateOnbordingSent}));
        }
        if(response.linkExpirationDate!=null){
            setPayee(payee => ({...payee, linkExpirationDate: response.linkExpirationDate}));
        }
        if(response.nonElectronicPayee!=null){
            setPayee(payee => ({...payee, nonElectronicPayee: response.nonElectronicPayee}));
        }
    }

    const validateForm = ()  => {
        let error = false;
        setNameError("");
        setCustomerTokenError("");
        setPlatformIdError("");
        setEmailError("");
        if(payee.name.length===0) {
            setNameError("Name cannot be blank")
            error=true;
        }
        if(payee.distributerPlatformId.length===0){
            setPlatformIdError("PlatformID cannot be blank")
            error=true;
        }
        if(payee.emailAddress.length===0){
            setEmailError("email cannot be blank")
            error=true;
        }
        return error;
    }

    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        const elementName = event.target.name;
        setPayee({
            ...payee,
            [elementName]: event.target.value,
        });
    };

    const handleSlInput = (event: React.FormEvent<SlInputElement>) => {
        const elementName = event.currentTarget.name;
        setPayee({
            ...payee,
            [elementName]: event.currentTarget.value,
        });
    };

    const handleAddressInput = (event: React.FormEvent<SlInputElement>) => {
        const elementName = event.currentTarget.name;
        setAddress({
            ...address,
            [elementName]: event.currentTarget.value,
        });
    };

    const saveButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if(!validateForm()) {
            payee.address = address;
            PayeeService.updatePayee(payee).then(() => {
                    navigate("/payeeList");
                    window.location.reload();
                }
            )
        }
    }

    const cancelButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate("/payeeList");
        window.location.reload();
    }


    return (
        <div>
            <Header isLoggedIn={true}></Header>
            <div className="container">

                <div className="_payee-form">

                    <div>
                        <div className="inputFieldElement">
                            <label>Supplier Name</label>
                            <div  className="paddedInput">
                                <SlInput style={{width: "50ch"}}
                                         name={"name"}
                                         onInput={handleSlInput}
                                         helpText={nameError}
                                         className={nameError.length > 0 ? 'warn' : ''}
                                         value={payee.name}
                                />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>Address </label>
                            <div  className="paddedInput">
                                <SlInput style={{width: "50ch"}}
                                         name={"addressLine1"}
                                         onInput={handleAddressInput}
                                         value={address.addressLine1}
                                />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label> </label>
                            <div  className="paddedInput">
                                <SlInput style={{width: "50ch"}}
                                         name={"addressLine2"}
                                         onInput={handleAddressInput}
                                         value={address.addressLine2}
                                />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>City</label>
                            <div  className="paddedInput">
                            <SlInput style={{width: "50ch"}}
                                     name={"city"}
                                     onInput={handleAddressInput}
                                     value={address.city}
                            />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>State</label>
                            <div  className="paddedInput">
                            <SlInput style={{width: "50ch"}}
                                     name={"state"}
                                     onInput={handleAddressInput}
                                     value={address.state}
                            />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>Zip Code</label>
                            <div  className="paddedInput">
                            <SlInput style={{width: "20ch"}}
                                     name={"postalCode"}
                                     onInput={handleAddressInput}
                                     value={address.postalCode}
                            />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="inputFieldElement">
                            <label>Distributer Platform Id</label>
                            <div  className="paddedInput">
                            <SlInput style={{width: "50ch"}}
                                     name={"state"}
                                     onInput={handleSlInput}
                                     helpText={platformIdError}
                                     className={platformIdError.length > 0 ? 'warn' : ''}
                                     value={payee.distributerPlatformId}
                            />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>Parent Platform Id</label>
                            <div  className="paddedInput">
                            <SlInput style={{width: "50ch"}}
                                     name={"state"}
                                     onInput={handleSlInput}
                                     value={payee.parentPlatformId}
                            />
                            </div>
                        </div>
                        <div className="inputFieldElement">
                            <label>Primary Email</label>
                            <div  className="paddedInput">
                            <SlInput style={{width: "50ch"}}
                                     name={"state"}
                                     onInput={handleSlInput}
                                     helpText={emailError}
                                     className={emailError.length > 0 ? 'warn' : ''}
                                     value={payee.emailAddress}
                            />
                            </div>
                        </div>
                        <div className="label">
                            <label>Payee Status: </label>
                            {payee.status}
                        </div>
                        <div className="label">
                            <label>Date Onboarding Request Sent: </label>
                            {payee.dateOnbordingSent}
                        </div>
                        <div className="label">
                            <label>Non-Electronic Only: </label>
                            {payee.nonElectronicPayee &&
                                <label>True</label>
                            }
                            {!payee.nonElectronicPayee &&
                                <label>False</label>
                            }
                        </div>
                        {isAdmin &&
                            <div>
                                <div>Bank Information</div>
                                <div className="_payee_bank_info_config _payee_bank_info_header_color">
                                    <div>Name on Account</div>
                                    <div>Account Type</div>
                                    <div>Routing</div>
                                    <div>Account Number</div>
                                </div>
                                <div className="_payee_bank_info_config">
                                    <div>{payeeBankinfo.accountHolderName}</div>
                                    <div>{payeeBankinfo.accountHolderType}</div>
                                    <div>{payeeBankinfo.routingNumber}</div>
                                    <div>{payeeBankinfo.accountNumber}</div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="_buttons">
                    <div></div>
                    <button className="okButton" onClick={saveButtonHandler}>Save</button>
                    <div></div>
                    <button className="cancelButton" onClick={cancelButtonHandler}>Cancel</button>
                </div>
            </div>
        </div>
    )
}
